import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import Grid from "../../components/grid/Grid"
import ProductGrid from "../../components/grid/schema/ProductGrid"
import StoryGrid from "../../components/grid/schema/StoryGrid"

export default ({ entryId }) => {
  const data = useStaticQuery(graphql`
    query {
      alloy {
        products {
          _id
          name
          description
          quantity
          price
          contents
          usage
          warnings
          images {
            link
            image {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          relatedProducts {
            name
            slug
            price
            quantity
            images {
              link
              image {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          relatedStories {
            title
            slug
            cover {
              link
              image {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  // https://github.com/gatsbyjs/gatsby/issues/10482
  // product(id: entryId) -> won't work in query
  // can't be placed in pageCreator and passed by cause the resolver didn't resolve the image until then
  const entry = data.alloy.products.find(({ _id }) => _id === entryId)
  const firstImage = entry.images.length > 0 ? entry.images[0] : null
  const relatedProducts = entry.relatedProducts.filter(product => product);
  const relatedStories = entry.relatedStories.filter(story => story);

  const additionalInfo = (info, title) =>
    info ? (
      <li>
        <div className="uk-accordion-title">{title}</div>
        <div className="uk-accordion-content">
          <p>{info}</p>
        </div>
      </li>
    ) : null

  return (
    <div id="product" className="main-page">
      <div
        id="product-overview"
        className="uk-grid uk-grid-collapse page-content"
      >
        {/* <h1 className="product-name" /> --> */}
        <div id="product-images" className="uk-width-2-5">
          {firstImage && <Img
            fluid={firstImage.image.childImageSharp.fluid}
            className="product-picture"
          />}
        </div>
        <div className="uk-padding uk-width-3-5">
          <div id="product-textbox">
            <div id="textbox-desc" uk-grid="" className="uk-grid uk-grid-stack">
              <h3 className="product-name uk-first-column">{entry.name}</h3>
              <div id="textbox-left" className="uk-grid-margin uk-first-column">
                <p className="product-desc">{entry.description}</p>
                <p className="product-amount">Menge: {entry.quantity}</p>
                <h3 className="product-price">€ {entry.price}</h3>
              </div>
            </div>
            <ul uk-accordion="" className="uk-accordion">
              {additionalInfo(entry.contents, "Inhalt")}
              {additionalInfo(entry.usage, "Anwendung")}
              {additionalInfo(entry.warnings, "Hinweise")}
            </ul>
          </div>
        </div>
      </div>

      {relatedProducts.length ? (
        <>
          <h2 className="section-title">Interessante Produkte</h2>
          <Grid
            items={relatedProducts.slice(0, 4)}
            render={entry => <ProductGrid {...entry} />}
          />
        </>
      ) : (
        ""
      )}

      {relatedStories.length ? (
        <>
          <h2 className="section-title">Interessante Stories</h2>
          <Grid
            items={relatedStories.slice(0, 4)}
            render={entry => <StoryGrid {...entry} />}
          />
        </>
      ) : (
        ""
      )}
    </div>
  )
}
