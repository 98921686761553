import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import Grid from "../../components/grid/Grid"
import ProductGrid from "../../components/grid/schema/ProductGrid"
import StoryGrid from "../../components/grid/schema/StoryGrid"

export default ({ entryId }) => {
  const data = useStaticQuery(graphql`
    query {
      alloy {
        stories {
          _id
          title
          subtitle
          text
          cover {
            link
            image {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          relatedProducts {
            name
            slug
            price
            quantity
            images {
              link
              image {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          relatedStories {
            title
            slug
            cover {
              link
              image {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  // https://github.com/gatsbyjs/gatsby/issues/10482
  // story(id: entryId) -> won't work in query
  // can't be placed in pageCreator and passed by cause the resolver didn't resolve the image until then
  const entry = data.alloy.stories.find(({ _id }) => _id === entryId)
  const relatedProducts = entry.relatedProducts.filter(product => product)
  const relatedStories = entry.relatedStories.filter(story => story)

  return (
    <div id="story" className="main-page">
      <div className="banner-image">
        {entry.cover && (
          <Img
            fluid={entry.cover.image.childImageSharp.fluid}
            className="banner-picture"
          />
        )}
      </div>
      <h1 className="content page-title">{entry.title}</h1>
      <h2 className="content section-title">{entry.subtitle}</h2>
      <div
        className="story-text page-content"
        dangerouslySetInnerHTML={{ __html: entry.text }}
      />
      {relatedProducts.length ? (
        <>
          <h2 className="section-title">Empfohlene Produkte</h2>
          <Grid
            items={relatedProducts.slice(0, 4)}
            render={entry => <ProductGrid {...entry} />}
          />
        </>
      ) : (
        ""
      )}

      {relatedStories.length ? (
        <>
          <h2 className="section-title">&Auml;hnliche Stories</h2>
          <Grid
            items={relatedStories.slice(0, 4)}
            render={entry => <StoryGrid {...entry} />}
          />
        </>
      ) : (
        ""
      )}
    </div>
  )
}
