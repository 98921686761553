import React, { useState, useEffect } from "react"

import "./Grid.styl"

const getGrid = grid => {
  const { items, gridSize } = grid
  const gridItem = []

  let x = 0
  let y = 0

  const setNextSpace = width => {
    if (y + width > gridSize) {
      // no space anymore in this row
      x += 1
      y = 0
    }

    if (!gridItem[x]) return

    if (!gridItem[x][y]) {
      // on this place no id is set
      // check next fields
      let check = true
      for (let i = 1; i < width; i++) {
        if (gridItem[x][y + 1]) check = false
      }
      if (check) return
    }
    y += 1

    setNextSpace(width)
  }

  const setItems = (itemId, width, height) => {
    if (!gridItem[x]) gridItem[x] = []
    gridItem[x][y] = itemId

    // fill other fields
    // width
    for (let i = 0; i < width; i++) {
      gridItem[x][y + i] = itemId

      // height
      for (let j = 0; j < height; j++) {
        if (!gridItem[x + j]) gridItem[x + 1] = []
        gridItem[x + j][y + i] = itemId
      }
    }
  }

  items.forEach(({ itemId, width, height }) => {
    setNextSpace(width)
    setItems(itemId, width, height)

    y += width
    if (y > gridSize - 1) {
      x += 1
      y = 0
    }
  })

  return gridItem
}

export default ({ items = [], render = () => {}, grid, imageSlider }) => {
  const [isDesktop, setIsDesktop] = useState(true) // it will start with desktop version
  const scrollBarBlockView = 15 // px
  const { gridSize = 4 } = grid || {}
  const gridItems = grid ? getGrid(grid) : []
  const containerProps = {
    className: "container",
    style: {
      minHeight: isDesktop
        ? `calc((${100 / gridSize}vw - ${scrollBarBlockView / gridSize}px) * ${
            grid ? gridItems.length : Math.ceil(items.length / 4)
          })`
        : `calc((${(100 / gridSize) * 2}vw - ${scrollBarBlockView /
            gridSize}px) * ${Math.ceil(items.length / 2)})`,
    },
  }

  useEffect(() => {
    // find out if Desktop
    const windowDesktop = window.matchMedia("(min-width: 1000px)")
    const isDesktop = ({ matches }) => setIsDesktop(matches)

    isDesktop(windowDesktop)
    windowDesktop.addListener(isDesktop)
  })

  if (imageSlider) {
    // add uk-lightbox to container props
    containerProps["uk-lightbox"] = "animation: slide"
  }

  return (
    <div className="grid">
      <div {...containerProps}>
        {items.map((item, index) => {
          let gridStyle = {}
          if (gridItems.length) {
            const { itemId, width, height } = item

            let x = gridItems.findIndex(item => item.includes(itemId))
            let y = gridItems[x].findIndex(item => item === itemId)

            gridStyle = {
              width: `calc(${(100 * width) / gridSize}vw - (${(scrollBarBlockView * width) / gridSize}px))`,
              height: `calc(${(100 * height) / gridSize}vw - (${(scrollBarBlockView * height) / gridSize}px))`,

              left: `calc((${100 / gridSize}vw  - ${scrollBarBlockView / gridSize}px) * ${y})`,
              top: isDesktop
                ? `calc((${100 / gridSize}vw - ${(scrollBarBlockView * height) / gridSize}px) * ${x})`
                : `calc((${(100 / gridSize) * 2}vw - (${scrollBarBlockView / 2}px)) * ${Math.floor(index / 2)})`,
            }
          } else {
            gridStyle = {
              width: `calc(${100 / gridSize}vw - (${scrollBarBlockView / gridSize}px))`,
              height: `calc(${100 / gridSize}vw - (${scrollBarBlockView / gridSize}px))`,

              left: `calc((${100 / gridSize}vw  - ${(scrollBarBlockView) / gridSize}px)  * ${index % gridSize})`,
              top: isDesktop
                ? `calc((${100 / gridSize}vw - (${scrollBarBlockView / gridSize}px)) * ${Math.floor(index / 4)})`
                : `calc((${(100 / gridSize) * 2}vw - (${scrollBarBlockView / gridSize}px)) * ${Math.floor(index / 2)})`,
            }
          }

          return (
            <div className="grid-item" key={index} style={gridStyle}>
              {render(item)}
            </div>
          )
        })}
      </div>
    </div>
  )
}
