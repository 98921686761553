import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import Grid from "../../components/grid/Grid"

export default ({ entryId }) => {
  const data = useStaticQuery(graphql`
    query {
      alloy {
        galleries {
          _id
          title
          images {
            link
            image {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  // https://github.com/gatsbyjs/gatsby/issues/10482
  // gallery(id: entryId) -> won't work in query
  // can't be placed in pageCreator and passed by cause the resolver didn't resolve the image until then
  const entry = data.alloy.galleries.find(({ _id }) => _id === entryId)

  const { title, images } = entry

  return (
    <div id="gallery" className="main-page">
        <h1 className="content page-title">{title}</h1>

          <div className="photo">
            <Grid items={images} height={22.2} imageSlider render={
                (entry) => 
                    <a href={entry.link} >
                        <Img fluid={entry.image.childImageSharp.fluid} className="grid-image"/>
                    </a>
            } />
          </div>
    </div>
  )
}
