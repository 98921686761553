import React, { useEffect } from "react"
import { navigate} from 'gatsby'

import StoryEntry from './entry/StoryEntry'
import ProductEntry from './entry/ProductEntry'
import GalleryEntry from './entry/GalleryEntry'

import "./entry.styl"

export default props => {
  const { type } = props.pageContext

  switch (type) {
    case "story":
      return <StoryEntry {...props.pageContext} />
    case "produkt":
      return <ProductEntry {...props.pageContext} />
    case "galerie":
      return <GalleryEntry {...props.pageContext} />
    default:
      console.warn("Entry typ ist nicht definiert!")
  }

  // No Entry type defined!
  useEffect(() => {
    navigate("/")
  }, [])
  return null
}
